<template lang="pug">
  main#main.machines-list
    b-container(fluid)
      wc-breadcrumb(icon='fal fa-conveyor-belt-alt')
      section.l-section
        .l-section-h
          b-row(cols='1' cols-lg='2')
            template(v-for='(item, index) in items')
              b-col.mb-3.pb-3.mb-md-4.pb-md-4
                machine-item(
                  :ref="`mid-${item.id}`"
                  :index='index'
                  :item.sync='item'
                  :rs='itemRelationships(item)'
                  :action-item-clicked='actionItemClicked'
                  @item-clicked='itemClicked')

          client-only
            infinite-loading(@infinite='infiniteHandler')
              div(slot='spinner')
                wc-loaders-infinite-spinner
              div(slot='no-more')
                wc-loaders-infinite-no-more
              div(slot='no-results')
                wc-loaders-infinite-no-results

          template(v-if='isLoading')
            wc-loaders-card-list
</template>

<script>
import WcBreadcrumb from '@components/shared/WcBreadcrumb'
import WcLoadersInfiniteNoMore from '@components/shared/loaders/infinite/WcLoadersInfiniteNoMore'
import WcLoadersInfiniteNoResults from '@components/shared/loaders/infinite/WcLoadersInfiniteNoResults'
import WcLoadersInfiniteSpinner from '@components/shared/loaders/infinite/WcLoadersInfiniteSpinner'
import MachineItem from '@components/layouts/shared/MachineItem'
import apiMachines from '@services/api/machines'
import List from '@common/list'

export default {
  name: 'machines-list',
  mixins: [List],
  components: {
    WcBreadcrumb,
    WcLoadersInfiniteNoMore,
    WcLoadersInfiniteNoResults,
    WcLoadersInfiniteSpinner,
    MachineItem,
  },
  props: {
    wns: {
      type: String,
      default: 'mac',
    },
  },
  methods: {
    routeParams(item) {
      return {
        show: [{ name: 'machines-show', params: { machine_id: item.attributes.sid } }],
      }
    },
    itemRelationships(item) {
      let rs = {}
      rs.organization = this.itemDataIncluded(
        this.$getDeep(item, 'relationships.organization.data.type'),
        this.$getDeep(item, 'relationships.organization.data.id')
      )
      rs.model = this.itemDataIncluded(
        this.$getDeep(item, 'relationships.model.data.type'),
        this.$getDeep(item, 'relationships.model.data.id')
      )
      return rs
    },
    channelSubscribe() {
      if (!this.$cable._channels.subscriptions['Api::V1::MachinesChannel']) {
        this.$cable.subscribe({
          channel: 'Api::V1::MachinesChannel',
        })
      }
    },
  },
  channels: {
    'Api::V1::MachinesChannel': {
      connected() {
        this.cable.machinesConnected = true
      },
      rejected() {
        this.cable.machinesConnected = false
      },
      received(response) {
        if (this.$refs[`mid-${response.id}`])
          this.$refs[`mid-${response.id}`][0].itemConn(response.online)
      },
      disconnected() {
        this.cable.machinesConnected = false
      },
    },
  },
  data() {
    return {
      apiBase: apiMachines,
      actionItemClicked: 'show',
      cable: {
        machinesConnected: false,
      },
    }
  },
  mounted() {
    this.channelSubscribe()
  },
}
</script>
