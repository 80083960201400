<template lang="pug">
  transition(name='fade')
    b-card.ls-machine-item.card-white.card-no-gutters.shadow(no-body)
      b-card-body.d-flex
        b-link.stretched-link(v-if='actionItemClicked' @click.stop.prevent='itemClicked')

        b-avatar.wc-bubble.wc-bubble-md.wc-bubble-br.wc-bubble-linked.shadow.mr-4(
          :src="$getDeep(rs.model, 'attributes.portrait_url')"
          variant='white'
          alt='')
          template(v-if="!$getDeep(rs.model, 'model.attributes.portrait_url')" #default)
            .wc-bubble-content.wc-bubble-linked(:class="isOnline ? 'bg-success' : 'bg-primary' ")
              .i-wrapper
                i.fal.fa-fw.fa-2x.fa-conveyor-belt-alt.text-white

        .d-flex.flex-column.flex-fill
          .info.d-flex.flex-column.justify-content-center.flex-grow-1.my-3
            .serial.font-weight-bold
              | {{ item.attributes.serial }}
            .model.small.text-muted.text-break(v-if='rs.model')
              | {{ $getDeep(rs.model, 'attributes.name') }}
            .organization.d-md-none.mt-3
              span.small.mr-3(v-if='rs.organization')
                i.fal.fa-fw.fa-building.text-primary
                |  {{ $getDeep(rs.organization, 'attributes.name') }}

          .d-flex.justify-content-between.align-items-end
            .organization
              .d-none.d-md-block
                span.small.mr-3(v-if='rs.organization')
                  i.fal.fa-fw.fa-building.text-primary
                  |  {{ $getDeep(rs.organization, 'attributes.name') }}
            .state
              b-badge.state.p-2(
                :variant="$wc.conf.states.machine.variants[item.attributes.state]"
                pill)
                i.fa-fw(:class="$wc.conf.states.machine.icons[item.attributes.state]")
                |  {{ $t('machine.states.' + item.attributes.state) }}

        .menu.position-absolute
          b-dropdown.ls-organization-item-menu(
            toggle-tag='div'
            toggle-class='p-0 text-muted shadow-none'
            variant='transparent'
            menu-class='border-0 p-0'
            right
            no-caret)
            template(#button-content)
              i.fal.fa-fw.fa-2x.fa-ellipsis-h.text-muted
</template>

<script>
export default {
  name: 'ls-machine-item',
  props: {
    index: Number,
    item: Object,
    rs: Object,
    actionItemClicked: String,
    actionDelete: Boolean,
  },
  computed: {
    isOnline() {
      return !!this.item.attributes.online
    },
  },
  methods: {
    itemClicked(event) {
      if (this.actionItemClicked) this.$emit('item-clicked', this.item, this.index, event)
    },
    itemConn(value) {
      this.item.attributes.online = value
    },
  },
}
</script>

<style lang="scss">
.ls-machine-item {
  min-height: 11rem;
  .menu {
    top: 1rem;
    right: 1rem;
    z-index: 1;
  }
}
</style>
